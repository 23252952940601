import React from 'react';
import ReactDOM from 'react-dom';


import firebase from 'firebase';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

console.log(process.env.REACT_APP_PROJECT_ID)
// Initialize the Firebase app
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_PROJECT_ID,
};
firebase.initializeApp(config);

/**
firebaseAdmin.initializeApp({
    databaseURL: 'https://curator.firebaseio.com',
    credential: firebaseAdmin.credential.cert(require("./fbadmin.json")),
}); */

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.scss';
import MainCommon from './MainCommon';
import Main from './Main';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';


function App() {
  const [authed, setAuthed] = useState(false)
  const [pp, setPP] = useState('');
  const [pv, setPV] = useState(false);
  const [userConnect, setUserConnect] = useState({})

  let unregisterAuthObserver;
  let uiConfig = {
    signInFlow: 'popup', //this.props.type === 'popup' ? 'popup' : 'credential',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: 'none',
    signinSuccessUrl: '/',
    callbacks: {
            //console.log(currentUser)
            
        
      }
  }

  useEffect(() => {
    unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
          if (user) {
            setAuthed(true)
             // if (user.uid) {//=== "4kxEUK4BVfSIzDR6GVm7sZROqag2" || user.uid === "ww0gWsISlVPDeWroRGUP4Hofrh13" || user.uid === 'WLwvbeMi6qa35kCqOzhi0BaKBCZ2' || user.uid==='fBpbYu1tNqPukukVjENIqV8Q1O32' || user.uid==="EPXXPJfH1ePtLFkXiK9wMYsITdF2")
               // setAuthed(true)
              } else setAuthed(false)
        });
    })

    const setUserData = (userConnects) => {
      //setUserConnect({...userConnect, [uid]: connectID})
      //console.log(userConnect)
      setUserConnect(userConnects)
    }


  return (
    <div className="App">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      {authed && <MainCommon authed={authed} />}
    </div>
  );
}

export default App;

import { debounce } from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import firebase from 'firebase'
// import '../../fonts/Plain-Medium.otf';
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './SubmitStreamModal.scss'
import axios from 'axios';
const BACKEND_API='http://localhost:8080'

const queryAddress = async (v, callback) => {
    let res = await getGPSFromAddress(v)
    callback(res)
}

const submitOptIntoBundle = async (optIn, festid) => {
    const body = JSON.stringify({
        optIn,
        uid: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        festid,
    })
    let token = await firebase.auth().currentUser.getIdToken()
    //console.log(body)
    return fetch(`${BACKEND_API}/fest/submitCommonOptInStream`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then(async (response) => {
            return response.json()
        })
        .then((result) => {
            return result
            return null
        }) //createForumArtistsPosttipChainID, passToBlogID, uid, blogIDsubmitOptIntoBundle(optIn, id);
}

const submitStream = async (data, festid) => {
    //console.log(url) {uid, name, blogID, payment_method, amount
    let newData = {}
    Object.keys(data).map((k) => (data[k] ? (newData[k] = data[k]) : (newData[k] = null)))
    //console.log(newData)
    console.log(festid)
    const body = JSON.stringify({
        data: newData,
        uid: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email,
        festid,
    })
    let token = await firebase.auth().currentUser.getIdToken()
    //console.log(body)
    return fetch(`${BACKEND_API}/fest/submitCommonStream`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then(async (response) => {
            return response.json()
        })
        .then((result) => {
            //console.log(result)
            return result
            return null
        }) //createForumArtistsPosttipChainID, passToBlogID, uid, blogIDsubmitOptIntoBundle(optIn, id);
}

const getGPSFromAddress = async (address) => {
    var cleanAddress = address.replace(/ /g, '+')

    const body = JSON.stringify({
        cleanAddress,
    })
    return fetch(`${BACKEND_API}/getGPSFromAddress`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    })
        .then(async (response) => {
            return response.json()
        })
        .then((result) => {
            let response = result
            console.log(response)
            if (response.status === 'OK') {
                let geolocation = {
                    latitude: response.results[0].geometry.location.lat,
                    longitude: response.results[0].geometry.location.lng,
                }
                let address = response.results[0].formatted_address
                let state, stateCode, city
                let components = response.results[0].address_components
                for (var i = 0; i < components.length; i++) {
                    if (components[i].types.indexOf('administrative_area_level_1') > -1) {
                        state = components[i].long_name
                        stateCode = components[i].short_name
                    }
                    if (components[i].types.indexOf('locality') > -1) {
                        city = components[i].long_name
                    }
                }
                return { address, geolocation, state, stateCode, city }
            }
            return null
        }) //createForumArtistsPosttipChainID, passToBlogID, uid, blogID
}

const SubmitButt = ({ success, loading, handleSubmitStream }) =>
    success ? (
        <div>SUCCESS! If approved, your stream will show up in the 'community' section. </div>
    ) : loading ? (
        <div />
    ) : (
        <div style={{margin: 24}}><button onClick={handleSubmitStream}>SUBMIT</button></div>
    )

const Field = ({ f: { label, name, value, placeholder }, updateSubmissionData }) => (
    <div className="clubFieldHolder">
        <div style={{ fontSize: 10, textTransform: 'uppercase' }}>{label}</div>
        <input
            className="mutekInputField"
            style={{ flex: 1, marginBottom: 24 }}
            type="text"
            onChange={updateSubmissionData}
            name={name}
            value={value}
            placeholder={placeholder}
        />
    </div>
)


const getUploadURL = async (filename) => {
    let token = await firebase.auth().currentUser.getIdToken()
    const body = JSON.stringify({
        uid: firebase.auth().currentUser.uid,
    })
    return await fetch(`${BACKEND_API}/getUploadURLimg`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body,
    })
        .then(async (response) => {
            return response.json()
        })
}
const uploadFileB2 = (uploadUrl, file, filename, uploadToken, hash, uid, blogID, onProgressCallback) => {
    return axios
        .post(uploadUrl, file, {
            headers: {
                Authorization: uploadToken,
                'Content-Type': 'image/jpeg', // helps prevent reflecting user-uploads as JS/HTML/viruses/etc
                'X-Bz-File-Name': filename,
                'X-Bz-Content-Sha1': hash,
                'X-Bz-Info-Filename': encodeURIComponent(file.name),
                'X-Bz-Info-Uid': uid,
                'X-Bz-Info-Blogid': blogID,
            },
            onUploadProgress: ({ loaded, total }) => {
                const totalProgress = parseInt((loaded / total) * 100)
                onProgressCallback(totalProgress)
                //     console.log('Uploading...', `${totalProgress}%`)
            },
        })
        .then((response) => response.data)
}
const fileHash = async (arrayBuffer) => {
    // get the SHA1 hash of a file's contents from an <input type="file"> field
    const hashBuffer = await crypto.subtle.digest('SHA-1', arrayBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
    return hashHex
}
const fileContents = async (file) => {
    // get the contents hash of a file from an <input type="file"> field
    return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            resolve(event.target.result)
        }
        reader.readAsArrayBuffer(file)
    })
}
const uploadB2 = async ( file, uid, onProgressCallback) => {
    console.log(file)
    const B2_BASE_URL = 'https://img.currents-andata.xyz/file/currents-images-prod/'
    // Pseudocode:
    // const uploaded_file = from the <input type="file"/>
    // const sha256_hash = hash(uploaded_file)
    // const uploadUrl = await serverAPI.uploadMusicURL(sha256_hash)
    // const publicUrl = await uploadSong(uplaoded_file, sha256_hash, `${sha256_hash}.mp3`)
    // const currentsID = await serverAPI.createMusic(uid, blogID, path=publicUrl)
    // this.uploadMusicDuration(currentsID, content, blogID)
    try {
        const contentArrayBuffer = await fileContents(file)

        // console.log("calculating file hash...")
        const hash = await fileHash(contentArrayBuffer)
        const filename = `${hash}.jpg`
        const publicUrl = `${B2_BASE_URL}${filename}`

        //console.log("Getting upload url...", filename, hash)
        const { uploadUrl, uploadToken } = await getUploadURL(filename)

        //console.log("Uploading file", uploadUrl, uploadToken, filename, file.size, file)
        const { fileId } = await uploadFileB2(
            uploadUrl,
            file,
            filename,
            uploadToken,
            hash,
            uid,
            'currents',
            onProgressCallback,
        )

        // console.log("Saving to firebase...", blogID, fileId, publicUrl)
        //console.log(uploadUrl)
        console.log(publicUrl)
        return publicUrl
        // console.log(currentsID)
        
    } catch (e) {
        console.log(e)
        return false
    }
}

const Address = ({ address, geolocation, setAddress, setGeolocation, debounceSearch }) => {
    return (
        <div>
            <div style={{ fontSize: 10, textTransform: 'uppercase' }}>Location (featured on world map)</div>
            <div className="cHorizontal cAlign">
                <input
                    type="text"
                    className="mutekInputField"
                    placeholder="required / address or city"
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value)
                        //debounce(queryAddress, 1000)(e.target.value)
                        debounceSearch(e.target.value, setGeolocation)
                    }}
                    style={{ marginBottom: 16 }}
                />
                {geolocation && (
                    <div className="pinkItem" style={{ fontSize: 10, marginLeft: 16 }}>
                        <div>LAT: {geolocation.geolocation.latitude}</div>
                        <div>LON: {geolocation.geolocation.longitude}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

const DateField = ({ name, label, submissionData, setDateData }) => (
    <div>
        <div style={{ fontSize: 10, textTransform: 'uppercase' }}>{label}</div>
        <ReactDatePicker
            locale='de'
            selected={submissionData[name]}
            onChange={(date) => {
                console.log(date.getTime())
                setDateData({ ...submissionData, [name]: date }, date)
            }}
            showTimeSelect
            dateFormat="Pp"
        />
    </div>
)
const END_DATE = new Date(1597507200000)
const START_DATE = new Date(1597453200000)

const SubmitStreamModal = (props) => {
    const [geolocation, setGeolocation] = useState(false)
    const debounceSearch = useCallback(debounce(queryAddress, 600), [])
    const { open, exit, isSignedIn } = props
    const [submissionData, setSubmissionData] = useState({
        stream: '',
        title: '',
        donate: '',
        currentsID: '',
        country: '',
        lat: '',
        lon: '',
        artist: '',
        description: '',
        image: null,
        profile: '',
        currents: '',
        startTime: new Date(),
        endTime: new Date(),
    })

    useEffect(() => {
        setSubmissionData({
            stream: '',
            title: '',
            donate: '',
            currentsID: '',
            country: '',
            lat: '',
            lon: '',
            artist: '',
            description: '',
            image: null,
            profile: '',
            currents: '',
            startTime: START_DATE,
            endTime: END_DATE,
        })
    }, [])

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [optin, setOptin] = useState(false)
    const [address, setAddress] = useState('')
    const [dateError, setDateError] = useState(false)

    const { stream, artist, description, profile, currents, image, title, country, donate, lat, lon } = submissionData

    const textFields = [
        {
            type: 'double',
            fields: [
                { name: 'artist', label: 'ARTIST NAME', placeholder: 'required', value: artist },
                { name: 'title', label: 'LIVESTREAM TITLE', placeholder: 'optional', value: title },
            ],
        },
        {
            type: 'double',
            fields: [
                {
                    name: 'stream',
                    label: 'LIVESTREAM LINK (Vimeo Live, Twitch, Youtube Live)',
                    placeholder: 'required',
                    value: stream,
                    url: true,
                },
                { name: 'profile', label: 'WEBSITE', placeholder: 'optional', value: profile },
            ],
        },
    ]

    let streamID = props.userData && props.userData.data && props.userData.data.streamID
    if (process.env.REACT_APP_NEWCURRENTS !== 'no') {
        streamID = false
    }

    const inputFields = (field) => {
        const { name, placeholder, value, url, type, fields } = field
        if (type === 'double')
            return (
                <div className="mutekHorizontal cMax100">
                    {fields.map((f) => (
                        <Field f={f} key={f.label} updateSubmissionData={updateSubmissionData} />
                    ))}
                </div>
            )
        return (
            <div className="mutekHorizontal">
                <Field f={field} key={field.label} updateSubmissionData={updateSubmissionData} />
            </div>
        )
    }

    //functions
    const updateSubmissionData = ({ target: { name, value } }) => {
        if (name === 'stream' && value.length > 6 && !value.match(/^[a-zA-Z]+:\/\//)) value = 'https://' + value
        setSubmissionData({ ...submissionData, [name]: value })
    }
    const storeFileLocally = (e) => setSubmissionData({ ...submissionData, image: e.target.files[0] })
    const renderTextInputs = textFields.map(inputFields)
    const loggedIn = true

    const handleSubmitStream = async (e) => {
        e.preventDefault()
        setLoading(true)
        
        let url = await uploadB2(submissionData.image, 'admin', (v) => {console.log(v)})
        //let url = await uploadImage(submissionData.image, props.uid, () => {})
        console.log('Submitting stream:', submissionData)
        let id = 'common2'
        let result = await submitStream(
            {
                ...submissionData,
                image: url,
                lat: !!geolocation && geolocation.geolocation.latitude,
                lon: !!geolocation && geolocation.geolocation.longitude,
                geolocation: !!geolocation && geolocation.geolocation,
            },
            id,
        )
        setLoading(false)
        if (result && result.success) {
            setSuccess(true)
        }
    }
    const streamOptInBundle = async (e) => {
        e.preventDefault()
        setLoading(true)
        let id = 'common2'
        let result = await submitOptIntoBundle(true, id)
        setLoading(false)
        if (result && result.success) {
            setOptin(true)
        }
    }

    const renderSubmittedStream = () => {
        return (
            <>
                <div>You've submitted a stream. You'll recieve more information via email soon!</div>
                <div vertical med />
            </>
        )
    }
    console.log(submissionData)
    return (
        <div open={open} exit={exit} title="SUBMIT A STREAM">
            {streamID ? (
                renderSubmittedStream()
            ) : loggedIn ? (
                success ? (
                    <div>
                        {submissionData && submissionData.currentsID && !optin ? (
                            <div>
                                Common is a space that is dedicated to turning the momentum of events into sustainable
                                support.
                                <br />
                                <br />
                                As someone with a Channel, you can join an ephemeral collective. This will allow
                                attendees to subscribe to all channels that have opted in. This will unlock patron
                                features to Common (private groupchats, videochat, badges).
                                <br />
                                <br />
                                It will only be able to be subscribed to during the 24 hours of the event, and attendees
                                will have access to your channel as long as they are subscribed. <br />
                                It will be $15, and will be evenly split between every channel that has decided to join.{' '}
                                <br />
                                Each iteration of Common will have its own ephemeral collective. <br />
                                <br />
                                <button  click={streamOptInBundle}>
                                    I'M IN
                                </button>
                                <button  click={exit}>
                                    NO THANKS
                                </button>
                            </div>
                        ) : loading ? (<div />
                        ) : (
                            <>
                                SUCCESS! If approved, your stream will show up in the 'community' section. We'll contact
                                you with next steps in a few days.{' '}
                            </>
                        )}
                    </div>
                ) : (
                    <form
                        //onSubmit={handleSubmitStream}
                        style={{
                            height: '480px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 auto',
                            textAlign: 'justify',
                        }}
                    >
                        {renderTextInputs}
                        <div style={{height: 24 }} />
                        {artist && stream && props.userOwnedBlogs && (
                            <div>
                                <div style={{ fontSize: 10, textTransform: 'uppercase' }}>
                                    SELECT A CHANNEL TO LINK YOUR STREAM AND ALLOW VIEWERS TO SUBSCRIBE:
                                </div>
                                <div className="cHorizontal">
                                    {props.userOwnedBlogs.map((blog, i) => {
                                        return (
                                            <div
                                                className={
                                                    submissionData.currentsID === blog.id
                                                        ? 'submitBlogItem selected'
                                                        : 'submitBlogItem'
                                                }
                                                key={'select' + i}
                                                onClick={() => {
                                                    setSubmissionData({
                                                        ...submissionData,
                                                        artist: blog.data.artist,
                                                        currentsID: blog.id,
                                                    })
                                                }}
                                            >
                                                <img className="cover" src={blog.data.coverPicURL} />
                                                <div
                                                    style={{
                                                        fontSize: 10,
                                                        lineHeight: '12px',
                                                        opacity: 0.54,
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    {blog.data.artist}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        {artist && stream && !props.userOwnedBlogs && false && (
                            <div>
                                <div style={{ fontSize: 10, textTransform: 'uppercase' }}>DONATION LINK:</div>
                                <div className="cHorizontal">
                                    <input
                                        type="text"
                                        className="mutekInputField"
                                        placeholder="optional"
                                        value={donate}
                                        onChange={(e) =>
                                            setSubmissionData({ ...submissionData, donate: e.target.value })
                                        }
                                        style={{ marginBottom: 16 }}
                                    />
                                </div>
                            </div>
                        )}
                        <div style={{height: 48}} />
                        {artist && stream && (
                            <>
                                {dateError && (
                                    <div
                                        className="pinkBG blackText"
                                        style={{ fontSize: 10, textTransform: 'uppercase' }}
                                    >
                                        START/END TIMES MUST BE BETWEEN 15:00 AUG 14 AND 06:00 AUG 15 CEST
                                        <br />
                                        YOUR TIME: {new Date(1597507200000).toString()} TO {new Date(1597453200000).toString()}
                                    </div>
                                )}
                                <div className="mutekHorizontal">
                                    <DateField
                                        name="startTime"
                                        label="start time (your time zone)"
                                        submissionData={submissionData}
                                        setDateData={(sd, date) => {
                                            let secs = date.getTime()
                                            setSubmissionData(sd)
                                            if (secs < END_DATE.getTime() && secs > START_DATE.getTime()) {
                                                setDateError(false)
                                            } else {
                                                setDateError(true)
                                            }
                                        }}
                                    />
                                    <DateField
                                        name="endTime"
                                        label="end time (your time zone)"
                                        submissionData={submissionData}
                                        setDateData={setSubmissionData}
                                    />
                                    <Address
                                        address={address}
                                        setAddress={setAddress}
                                        geolocation={geolocation}
                                        setGeolocation={setGeolocation}
                                        debounceSearch={debounceSearch}
                                    />
                                </div>
                                <div vertical med />
                            </>
                        )}
                        {artist && stream && geolocation && (
                            <div className="mutekHorizontal">
                                <button color="white">
                                    <label htmlFor="imgCov">&nbsp;&nbsp;&nbsp;SELECT IMAGE (required): </label>
                                    <input
                                        style={{ opacity: 0, maxWidth: 24, overflow: 'hidden' }}
                                        id="imgCov"
                                        name="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={storeFileLocally}
                                    />
                                </button>
                                <div>
                                    {image ? (
                                        <img
                                            style={{ height: 48, width: 84, objectFit: 'cover', marginLeft: '40px' }}
                                            src={URL.createObjectURL(image)}
                                            alt="upload preview"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}
                        {artist && stream && geolocation && image && (
                            <SubmitButt success={success} loading={loading} handleSubmitStream={handleSubmitStream} />
                        )}
                    </form>
                )
            ) : (
                <div>
                </div>
            )}
        </div>
    )
}

export default SubmitStreamModal

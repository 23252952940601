import React, { useEffect, useState } from 'react';
//import firebase from 'firebase';
import firebase from 'firebase';
import ReactDatePicker from 'react-datepicker';
import Modal from 'react-modal';
/**
firebase.initializeApp({
  databaseURL: "https://curator.firebaseio.com",
  credential: firebase.credential.cert(require("./fbadmin.json"))
}); */
import "react-datepicker/dist/react-datepicker.css";

/*
organizers: {
    roomname: {
        image
        location
        name: curatorname
        subname: curatorname presented
        type: channel or collective
    }
}
*/

import { setTimewarp, disarmTimewarp, getUserData } from './api'
import TopBoxes from './TopBoxes';
import CommonRooms from './CommonRooms';
import SubmitStreamModal from './SubmitModal';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Main = props => {
    const [editModal, setEditModal] = useState(false)
    const [getStuff, setGetStuff] = useState(false)
    const [showData, setShowData] = useState(null)
    const [mutekData, setMutekData] = useState({})
    const [galleryData, setGalleryData] = useState([])
    const [studioData, setStudioData] = useState([])
    const [cSubData, setCSubData] = useState([])
    const [cHubData, setCHubData] = useState([])
    const [cShowData, setCShowData] = useState([])

    const [timewarplock, setTimewarplock] = useState(false)
    const [time, setTime] = useState(null)
    
    const [timeRow, setTimeRow] = useState(null)
    const [timeField, setTimeField] = useState(null)
    const [timeS, setTimeS] = useState(null)
    const [saving, setSaving] = useState(false)
    const [add, setAdd] = useState(false)
    const [edit, setEdit] = useState(false)
    const [editFields, setEditFields] = useState(false)
    const [addFields, setAddFields] = useState(false)

    const [totSub, setTotSub] = useState(0)

    const [newCode, setNewCode] = useState(null)

    const [stripeAccounts, setStripeAccounts] = useState({})

    const [presaves, setPresaves] = useState([])
    const [stripeDonations, setStripeDonations] = useState([])
    const [duplicates, setdups] = useState([])
    const [singles, setSingles] = useState([])

    const [reported, setReported] = useState([])
    const [banned, setBanned] = useState([])
    
    const FEST_CODE = 'common3'

    useEffect(() => {
        fetchData()
    },[])
    useEffect(() => {
        //if (getStuff) getPayments()
    }, [getStuff]);

    let getUser = async uid => {
        let ue = await firebase.firestore().collection('emailLookup').where('uid', '==', uid).get().then(qs => {
            return qs && qs.docs && qs.docs[0] && qs.docs[0].data()
        })
        return ue
    }

    let fetchData = async () => {
        let sub = 0;
        try {
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('shows').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    setShowData(s)
                }
            })
            let m = await firebase.firestore().collection('festival').doc(FEST_CODE).get()
            setMutekData(m.data())
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('gallery').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    setGalleryData(s)
                }
            })
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('studios').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    console.log(s)
                    setStudioData(s)
                }
            })
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('community').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    setCShowData(s)
                }
            })
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('submissions').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    setCSubData(s)
                }
            })
            await firebase.firestore().collection('reportedUsers').get().then(async data => {
                
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    console.log(s)
                    setReported(s)
                }
            })
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('banned').get().then(async data => {
                if (data && data.docs.length > 0) {
                    let s = data.docs.map(doc => {
                        return {data: doc.data(), id: doc.id}
                    })
                    setBanned(s)
                }
            })

            
        } catch (e) {console.log(e)}
        return
    }

    const setBan = async (r) => {
        try {

                //console.log(url) {uid, name, blogID, payment_method, amount
            //const body = JSON.stringify({
            //    uid, emailAddress
            //})
            //console.log(body)
            let token = await firebase.auth().currentUser.getIdToken();
            await fetch(`${BACKEND_URL}/chat/ban`, {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    uid: r.data.userId,
                    message: r.data.message,
                    id: r.id,
                    code: 'mutekSFNexus'
                })
            
            }).then(async r => {
                return r.json()
                
                //setTimewarplock(!timewarplock)
            }).then(async r =>{
                await fetchData()
                console.log(r)
            })
        } catch(e) {
            console.log(e)
        }
    }

    const saveField = async (id) => {
        setSaving(true)
        if (!saving) {
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('shows').doc(id).set(editFields, {merge: true})
            await fetchData()
            setSaving(false)
            setEdit(false)
        } 
    }
    const saveAdd = async () => {
        setSaving(true)
        if (!saving) {
            await firebase.firestore().collection('festival').doc(FEST_CODE).collection('shows').add(addFields)
            await fetchData()
            setSaving(false)
            setAddFields({})
            setAdd(false)
        } 
    }

    const rejectSubmission = async (id) => {
        let token = await firebase.auth().currentUser.getIdToken();
        await fetch(`${BACKEND_URL}/fest/removeSubmission`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id
            })
        }).then(async response => {
            return response.json()
        }).then(async result => {
            await fetchData()
        })
    }
    const deleteShow = async (id) => {
        let token = await firebase.auth().currentUser.getIdToken();
        await fetch(`${BACKEND_URL}/fest/deleteShow`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id, festid: FEST_CODE
            })
        }).then(async response => {
            return response.json()
        }).then(async result => {
            await fetchData()
        })
    }
    const approveSubmissionRoom = async (id, room=false) => {
        let token = await firebase.auth().currentUser.getIdToken();
        return await fetch(`${BACKEND_URL}/fest/approveSubmissionRoom`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id, room, festid: FEST_CODE
            })
        }).then(async response => {
            return await response.json()
        }).then(async result => {
            console.log(result)
            return await fetchData()
        })
    }

    const showfield = (id, field) => {
        
    }

    const timeSelect = () => {
        if (!timeS) return null;
        return <ReactDatePicker
            selected={timeS}
            onChange={(date) => {
                if (timeRow === 'edit') {
                    setEditFields({...editFields, [timeField]: date})
                } else if (timeRow === 'add') {
                    setAddFields({...addFields, [timeField]: date})
                }
                setTimeRow(null)
                setTimeField(null)
                setTimeS(null)
                console.log(editFields)
            }}
            showTimeSelect
            dateFormat="Pp"
        />
    }

    const showEdit = (s) => {
        return <div key={s.id} style={{display: 'flex', flexDirection: 'row', padding: 8}}>    
        <div style={{width: 48}}><button onClick={() => {
            saveField(s.id)
        }}>{saving?"SAVING...":"Save"}</button>
        <button  style={{width: 48}} onClick={() => {
            setSaving(false)
            setEdit(false)
        }}>CANCEL</button>
        </div>
        <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>
            <input value={editFields.name} onChange={e=>setEditFields({...editFields, name: e.target.value})} />
        </div>
        <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.country} onChange={e=>setEditFields({...editFields, country: e.target.value})} /></div>
        <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.nameB} onChange={e=>setEditFields({...editFields, nameB: e.target.value})} /></div>
        <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.countryB} onChange={e=>setEditFields({...editFields, countryB: e.target.value})} /></div>
        <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.title} onChange={e=>setEditFields({...editFields, title: e.target.value})} /></div>
        <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.type} onChange={e=>setEditFields({...editFields, type: e.target.value})} /></div>
        <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.link} onChange={e=>setEditFields({...editFields, link: e.target.value})} /></div>
        <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.linkA} onChange={e=>setEditFields({...editFields, linkA: e.target.value})} /></div>
        <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.linkB} onChange={e=>setEditFields({...editFields, linkB: e.target.value})} /></div>
        <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.image} onChange={e=>setEditFields({...editFields, image: e.target.value})} /></div>
        <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><button onClick={() => {
            setTimeField('startTime')
            setTimeRow('edit')
            setTimeS(editFields.startTime)
        }}>{editFields.startTime.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})}</button></div>
        <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><button onClick={() => {
            setTimeField('endTime')
            setTimeRow('edit')
            setTimeS(editFields.endTime)
        }}>{editFields.endTime.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})}</button></div>
        <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.lat} onChange={e=>setEditFields({...editFields, lat: e.target.value})} /></div>
        <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.lon} onChange={e=>setEditFields({...editFields, lon: e.target.value})} /></div>
        <div style={{width: 180, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.description} onChange={e=>setEditFields({...editFields, description: e.target.value})} /></div>
    </div>
    }

    const renderCommunity = () => {
        return <div style={{marginTop: 24, marginBottom: 24, border: '1px solid rgba(255,255,255.54)'}}>

        <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>  
            
            <div style={{width: 128, fontSize: 12}}>NAME</div>
            <div style={{width: 128, fontSize: 12}}>TITLE</div>
            <div style={{width: 128, fontSize: 12}}>STREAMLINK</div>
            <div style={{width: 128, fontSize: 12}}>IMAGE</div>
            <div style={{width: 128, fontSize: 12}}>PROFILE/WEBSITE</div>
            <div style={{width: 80, fontSize: 12}}>CURRENTS ID</div>
            <div style={{width: 80, fontSize: 12}}>START</div>
            <div style={{width: 80, fontSize: 12}}>END</div>
            <div style={{width: 80, fontSize: 12}}>LAT</div>
            <div style={{width: 80, fontSize: 12}}>LON</div>
            <div style={{width: 180, fontSize: 12}}>BIO/DESCRIPTION</div>
        </div>
            
                    {cShowData.map((s,si)=>{
                        if(s.id === edit) {
                            return showEdit(s) //not used right now
                        }
                        return <div key={s.id} style={{display: 'flex', flexDirection: 'row', padding: 8}}>    
                            <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.name}</div>
                            <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.title}</div>
                            <a href={s.data.stream} style={{width: 128, maxWidth: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.stream}</a>
                            <img style={{width: 64, marginRight: 64, height: 32, objectFit: 'cover'}} src={s.data.image}/>
                            <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.profile}</div>
                            <div style={{width: 80, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.currentsID}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.startTime&&new Date(s.data.startTime.seconds*1000).toString()}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.endTime&&new Date(s.data.endTime.seconds*1000).toString()}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.lat}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.lon}</div>
                            <div style={{width: 180, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.description}</div>
                        </div>
                    })}
        </div>
    }
    const renderCommunitySubs = () => {
        return <div style={{marginTop: 24, marginBottom: 24, border: '1px solid rgba(255,255,255.54)'}}>

        <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>  
            
            <div style={{width: 128, fontSize: 12}}>NAME</div>
            <div style={{width: 128, fontSize: 12}}>TITLE</div>
            <div style={{width: 128, fontSize: 12}}>STREAMLINK</div>
            <div style={{width: 128, fontSize: 12}}>IMAGE</div>
            <div style={{width: 128, fontSize: 12}}>PROFILE/WEBSITE</div>
            <div style={{width: 80, fontSize: 12}}>CURRENTS ID</div>
            <div style={{width: 80, fontSize: 12}}>START</div>
            <div style={{width: 80, fontSize: 12}}>END</div>
            <div style={{width: 80, fontSize: 12}}>LAT</div>
            <div style={{width: 80, fontSize: 12}}>LON</div>
            <div style={{width: 180, fontSize: 12}}>BIO/DESCRIPTION</div>
            <div style={{width: 180, fontSize: 12}}>APPROVAL BUTTONS</div>
        </div>
            
                    {cSubData.map((s,si)=>{
                        if(s.id === edit) {
                            return <div key={s.id} style={{display: 'flex', flexDirection: 'row', padding: 8}}>    
                                <div style={{width: 48}}><button onClick={() => {
                                    saveField(s.id)
                                }}>{saving?"SAVING...":"Save"}</button>
                                <button  style={{width: 48}} onClick={() => {
                                    setSaving(false)
                                    setEdit(false)
                                }}>CANCEL</button>
                                </div>
                                <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>
                                    <input value={editFields.name} onChange={e=>setEditFields({...editFields, name: e.target.value})} />
                                </div>
                                <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.country} onChange={e=>setEditFields({...editFields, country: e.target.value})} /></div>
                                <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.nameB} onChange={e=>setEditFields({...editFields, nameB: e.target.value})} /></div>
                                <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.countryB} onChange={e=>setEditFields({...editFields, countryB: e.target.value})} /></div>
                                <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.title} onChange={e=>setEditFields({...editFields, title: e.target.value})} /></div>
                                <div style={{width: 60, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.type} onChange={e=>setEditFields({...editFields, type: e.target.value})} /></div>
                                <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.link} onChange={e=>setEditFields({...editFields, link: e.target.value})} /></div>
                                <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.linkA} onChange={e=>setEditFields({...editFields, linkA: e.target.value})} /></div>
                                <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.linkB} onChange={e=>setEditFields({...editFields, linkB: e.target.value})} /></div>
                                <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.image} onChange={e=>setEditFields({...editFields, image: e.target.value})} /></div>
                                <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><button onClick={() => {
                                    setTimeField('startTime')
                                    setTimeRow('edit')
                                    setTimeS(editFields.startTime)
                                }}>{editFields.startTime.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})}</button></div>
                                <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><button onClick={() => {
                                    setTimeField('endTime')
                                    setTimeRow('edit')
                                    setTimeS(editFields.endTime)
                                }}>{editFields.endTime.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})}</button></div>
                                <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.lat} onChange={e=>setEditFields({...editFields, lat: e.target.value})} /></div>
                                <div style={{width: 80, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.lon} onChange={e=>setEditFields({...editFields, lon: e.target.value})} /></div>
                                <div style={{width: 180, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}><input value={editFields.description} onChange={e=>setEditFields({...editFields, description: e.target.value})} /></div>
                            </div>
                        }
                        return <div key={s.id} style={{display: 'flex', flexDirection: 'row', padding: 8}}>    


                            <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.name}</div>
                            <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.title}</div>
                            <a href={s.data.stream} style={{width: 128, maxWidth: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.stream}</a>
                            <img style={{width: 64, marginRight: 64, height: 32, objectFit: 'cover'}} src={s.data.image}/>
                            <div style={{width: 128, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.profile}</div>
                            <div style={{width: 80, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.currents}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.startTime&&new Date(s.data.startTime.seconds*1000).toString()}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.endTime&&new Date(s.data.endTime.seconds*1000).toString()}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.lat}</div>
                            <div style={{width: 80, fontSize: 8,borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.lon}</div>
                            <div style={{width: 180, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 8, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.description}</div>
                            <div className="editButton" onClick={async() => {
                                await approveSubmissionRoom(s.id)
                                fetchData()
                            }}>COMMUNITY</div>
                            {mutekData && mutekData.rooms && mutekData.rooms.map((r,i) => {
                                return <div key={r} className="editButton" onClick={async() => {
                                    await approveSubmissionRoom(s.id,i)
                                    fetchData()
                                }}>ROOM {i}</div>
                            })}
                            <div className="editButton" onClick={async() => {
                                await rejectSubmission(s.id)
                                fetchData()
                            }}>REJECT</div>
                        </div>
                    })}
        </div>
    }


    return <div style={{textAlign: 'left', marginLeft: 48, height: '100vh', overflow: 'scroll'}}>
        <TopBoxes time={time} setTime={setTime} timewarplock={timewarplock} setTimewarp={setTimewarp} setTimewarplock={setTimewarplock} banned={banned} setBan={setBan} reported={reported}/>

        <div style={{display: 'flex', flexDirection: 'row', margin: 8, height: 64}} />
        <div>
            INSTRUCTIONS
            <ol>
                <li><span style={{color: 'red'}}>[Each Artist]</span> Instruct each artist in your room to submit their show at <u>COMMON.CURRENTS.FM</u></li>
                <li>IF YOU/YOUR ARTISTS ARE USING OUR LIVESTREAMING ACCOUNTS - Please email us and we will arrange this. Please have them put their own website in the stream link.  </li>
                <li><span style={{color: 'green'}}>[You as Room Lead]</span> APPROVE show for your room in "submissions" below. <u><b>Please refresh to see updates.</b></u></li>
                <li><span style={{color: 'green'}}>[You as Room Lead]</span> You can delete the show here by <u>clicking remove <b>(and then refresh)</b></u>, but to EDIT let us know</li>
                <li>After clicking each button, refresh</li>
            </ol>
            THIS DASHBOARD WILL CLOSE DOWN 72 HOURS BEFORE THE EVENT STARTS. 
            We'll reach out to you this week to show you how to test the streams. 
        </div>
        <div style={{display: 'flex', flexDirection: 'row', margin: 8, height: 64}} />
        
        {timeSelect()}
        {/*<SubmitStreamModal />*/}
        <div className="dHorizontal m100">
            <CommonRooms rooms={mutekData && mutekData.rooms} 
                organizers={mutekData && mutekData.organizers} 
                showData={showData}
                permissions={[0,1,2,3,4,5]}
                setEdit={setEditModal}
                deleteShow={deleteShow} 
                fetchData={fetchData}
            />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', margin: 8, height: 64}} />
        <div style={{margin: 24}}>SUBMISSIONS</div>
        {renderCommunitySubs()}
        <div style={{display: 'flex', flexDirection: 'row', margin: 8, height: 64}} />
        <div style={{margin: 24}}>COMMUNITY</div>
        {renderCommunity()}
        <Modal
            isOpen={editModal}
            onRequestClose={()=>setEditModal(false)}
            className="dashModal"
        >
            <div>TO EDIT THIS DATA, PLEASE CLOSE THIS WINDOW, CLICK 'REMOVE', and RESUBMIT THROUGH https://common-currents-staging.web.app/</div>
            <div style={{minHeight: 32}} />
            {editModal && <>
                {['artist', 'title', 'link', 'image', 'lat','lon','title','currentsID','profile'].map(t=>{
                    return <div>
                        <div style={{fontSize: 8, opacity: .54}}>{t}</div>
                        <div>{editModal.data[t]}</div>
                        <div style={{height: 8}} />
                    </div>
                })}
                
            </>}

        </Modal>
    </div>;
        
}

export default Main;
import React from 'react';

const TopBoxes = (props) => {
    const { timewarplock, disarmTimewarp, setTimewarp, setBan, setTimewarplock, time, setTime, reported, banned } = props
        return <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
            <div style={{padding: 16, width: 240, border: '1px solid red'}}>
                <div style={{color: 'red'}}>TIME SIMULATOR - NOT YET FUNCTIONAL</div>
                <div>
                    <button onClick={async () => {    
                        if (timewarplock) {
                            await disarmTimewarp(setTimewarplock)
                        } else {
                            setTimewarplock(true)
                        }
                    }}>{timewarplock ? "DISARM SAFETY PLZ do THIS" : "TOGGLE UNLOCK SAFETY"}</button>
                    {timewarplock && <div>
                        <div style={{color: 'red'}}>INPUT # HOURS PAST 4PM - DISARM SAFETY WHEN DONE</div>
                        <input value={time} type='number' onChange={e=>setTime(e.target.value)} />
                        <button onClick={async () => {
                            await setTimewarp(time)
                        }}>DEPLOY TIME MACHINE</button>
                    </div>}
                </div>
            </div>
            <div className="topBox">
                <div style={{width: '30vw', maxHeight: 420, overflow: 'scroll'}}>
                    <div style={{width: '50%'}}>REPORTED USERS</div>
                    {reported && reported.map(r => {
                        return <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>  
                            <div style={{width: 240, fontSize: 10}}>{r.data.userId}</div>
                            <div>{r.data.message}</div>
                            <button onClick={() => {setBan(r)}}>BAN</button>
                        </div>
                    })}
                </div>
            </div>
            <div className="topBox">
                <div style={{width: '30vw', maxHeight: 420, overflow: 'scroll'}}>
                    <div>BANNED USERS</div>
                    {banned && banned.map(r => {
                        return <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>  
                            <div>{r.id}</div>
                            <div>{r.data.message}</div>
                        </div>
                    })}
                </div>
                
            </div>
        </div>
}

export default TopBoxes
import axios from 'axios';
import firebase from 'firebase';

const BACKEND_API =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : process.env.REACT_APP_BACKEND_URL;



export const server = {
  editShow: async (name, nameB, country, location, link, linkA, linkB, room, image, currentsID, add=false) => {
    const token = await firebase.auth().currentUser.getIdToken();
    return await fetch(`${BACKEND_API}/editMutekShow`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        name, nameB, country, location, link, linkA, linkB, room, image, currentsID, add
      })
    }).then(response => {
        //console.log(response)
        return response.json();
      }).then (result => {
        //console.log("code accepted:")
        console.log(result)
        return result})
  },
}

export const setTimewarp = async (time) => {
  try {

      let token = await firebase.auth().currentUser.getIdToken();
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/setTimewarp`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              timewarp: true,
              number: time,
              code: 'mutekSFTIMEWARP'
          })
      
      }).then(async r => {
          return r.json()
          //setTimewarplock(!timewarplock)
      }).then(r =>{
          console.log(r)
      })
  } catch(e) {
      console.log(e)
  }
}

export const disarmTimewarp = async (callback) => {
  let token = await firebase.auth().currentUser.getIdToken();
  try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/killTimewarp`, {
          method: 'post',
          headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
          },
      }).then(r => {
          callback(false)
      })
  } catch(e) {
      console.log(e)
  }
}

const getUserData = async (ps, setPresaves) => {
  let newu = await Promise.all(ps.map(async p=> {
      if (!p.data.emailAddress && p.data.uid) {
          let ue = await firebase.firestore().collection('emailLookup').where('uid', '==', p.data.uid).get().then(qs => {
              return qs && qs.docs && qs.docs[0] && qs.docs[0].data().email
          })
          return {...p, data: {...p.data, emailAddress: ue}}
      } else return p
  }))
  setPresaves(newu)
  
}
import React from 'react';
import moment from 'moment-timezone'


const CommonRooms = props => {

    if (props.rooms) {
        return props.rooms.map((r,i) => {
            return <div className="commonRoom">
                <div key={r} style={{fontWeight: 600, fontSize: 20, textAlign: 'left', margin: 8}}>ROOM {i} </div>
                <div style={{marginBottom: 8}}>{props.organizers && props.organizers[r] && props.organizers[r].name}</div>
                <div>{props.organizers && props.organizers[r] && props.organizers[r].subname}</div>
                <div>{props.organizers && props.organizers[r] && props.organizers[r].location}</div>
                {props.showData && props.showData.filter(s=>s.data.room===i).sort((a,b) => a.data.startTime.seconds < b.data.startTime.seconds ? -1 : 1).map((s,si)=>{
                    return <div key={s.id} style={{display: 'flex', flexDirection: 'row', padding: 8}} className="show">    
                        <div className="smallText">
                            <div>LOCAL TIMES:</div>
                            <div>START: {moment(new Date(s.data.startTime.seconds*1000)).format("HH:mm A")}</div>
                            <div>END: {moment(new Date(s.data.endTime.seconds*1000)).format("HH:mm A")}</div>
                        </div>
                        <img className="thumb" src={s.data.image} />
                        <div style={{width: 128, borderRight: '1px solid rgba(0,0,0,.2)'}}>{s.data.name}</div>
                        {props.permissions && props.permissions.includes(i) && <div className="dHorizontal">
                            <div className="editButton" onClick={async () => {
                                props.setEdit(s)
                            }}>INFO</div>
                            <div className="editButton" onClick={async () => {
                                await props.deleteShow(s.id)
                                props.fetchData()
                            }}>REMOVE</div>
                        </div>}
                    </div>
                })}
            </div>
        })
    }
    return null;
}

export default CommonRooms;